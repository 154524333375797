import axios from 'axios'
// import { Message, MessageBox } from 'element-ui'
// import store from '../store'
// var ip = 'test.application.com'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import qs from 'qs'
// console.log(ip)
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // api的base_url
  timeout: 60000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(config => {
    // config.headers['authToken'] = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJNaW5nQ2hlIiwiZXhwIjoyNDM1MzU5OTEwLCJ1c2VySWQiOjIwMTkwNDIwNjUzOTE5LCJicmFuZElkIjoyNTYsInBob25lIjoiMTg3MzgxMDg3ODIiLCJyb2xlSWQiOjF9.uAjNPVsC8RuFZA09HNuSxZw2JmMidXB5eAn6uWXTpSnH5aTOp-DU4ZnISxqqYUAVoiyRNdTvQncsJac6af3KsQ'
    const token = sessionStorage.getItem(ACCESS_TOKEN)
  // console.log(config,"参数")
  if (token && !config.headers.noToken) {
    // config.headers['access-token'] = token

    var tokenObj = new Object()
    tokenObj[ACCESS_TOKEN] = token
    // console.log(config.params,'config')
    if(config.params){
      config.params = Object.assign(config.params,tokenObj)
    }else{
      config.data = Object.assign(config.data,tokenObj)
    }
  }
  config.data = qs.stringify(config.data)
  return config
}, error => {
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {

    const res = response.data
    console.log(res, "res.....")
    if (res.resp_code == '000005') {
      // MessageBox.confirm('您的登录已过期，可以取消继续留在该页面，或者重新登录', '确定登出', {
      //   confirmButtonText: '重新登录',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   store.dispatch('FedLogOut').then(() => {
      //     location.reload()// 

      //   })
      // })
    } else if (res.resp_code == '000000') {

      return res
    } else if (res.resp_code == '000012') {

      return res
    } else if (res.resp_code == '999999') {

      return res
    } else {

      // Message({
      //   message: res.resp_message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return response.data
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 404:
          error.message = '请求出错(404)'
          Message({ message: '请求出现了404！请稍后重试！' })
          break;
        case 401:
          MessageBox.confirm('您的登录已过期，可以取消继续留在该页面，或者重新登录', '确定登出', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            store.dispatch('FedLogOut').then(() => {
              location.reload()// 为了重新实例化vue-router对象 避免bug
            })
          })
          break;
        default:
          // error.message = `连接出错(${error.response.status})!`;
          // Message({
          //   message: '网络丢失了吆！请稍后重试！',
          //   type: 'error',
          //   duration: 5 * 1000
          // })
      }
    }
    return Promise.reject(error)
  }
)
export default service

