<template>
  <div>
    <div class="nav-item">
      <ul>
        <li v-for="(item,index) in nav" :key="index">
          <a href="#">{{item}}</a>
        </li>
      </ul>
    </div>
    <div class="nav-body">
      <!-- 侧边导航 -->
      <div class="nav-side" ref="navSide">
        <ul>
          <li v-for="item in classifyList" class="classify_icon"  @mouseenter="showDetail(item)" >
            <img :src="item.imageUrl"   alt="">
            <span class="nav-side-item">{{item.cateName}}</span>
          </li>
        </ul>
      </div>
      <div class="nav-content">
        <!-- 幻灯片 -->
        <div>
          <Carousel autoplay loop>
              <CarouselItem  v-for="(item, index) in marketing.CarouselItems" :key="index">
                <router-link to="/goodsList">
                  <img :src="item">
                </router-link>
              </CarouselItem>
          </Carousel>
        </div>
        <div class="nav-show">
          <div class="nav-show-img" v-for="(item, index) in marketing.activity"  :key="index">
            <!-- <router-link to="/goodsList"> -->
              <img :src="item">
            <!-- </router-link> -->
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="detail-item-panel panel-1" :duration="{ enter: 100, leave: 100 }" v-show="panel1" ref="itemPanel1" @mouseleave="hideDetail(1)">
        <div class="nav-detail-item">
         <span>{{active.cateName}}</span>
        </div>
        <ul class="detail-item-ul" > 
          <li v-for="(items, index) in classifySonList" @click="goods(items)"  :key="index" class="detail-item-row">
              <img :src="items.imageUrl"   alt="">
              <div>{{items.cateName}}</div>
            
          </li>
        </ul>
      </div>
    </transition>
   
  </div>
</template>

<script>
import store from '@/vuex/store';
import { mapState } from 'vuex';
import { classifyQuery,classifySonQuery } from '@/api'
export default {
  name: 'HomeNav',
  data () {
    return {
      panel1: false,
      nav: [
        '秒杀',
        "美妆",
        "运动健身",
        // '优惠券',
        '闪购',
        // '拍卖',
        '服装城',
        '超市',
        '生鲜',
        '全球购',
        // '金融'
      ],
      classifyList:[],
      active:{},
      classifySonList:[]
    };
  },
  computed: {
    ...mapState(['marketing'])
  },
  methods: {
    showDetail (item) {
      this.active=item
        classifySonQuery(item.id).then(res =>{
        this.classifySonList=res.data.content
        console.log(this.classifySonList,'classifnSonList')
      })
      this.panel1 = true
    },
    hideDetail (index) {
       this.panel1 = false
    },
    _classifyQuery(){
      classifyQuery().then(res =>{
        this.classifyList=res.data.content
        console.log(this.classifyList,'classifyList')
      })
    },
     goods(item){
        this.$router.push({path: '/goodsList', query: { goodsId: item.parentId }});
      
    },
  },
  created() {
    this._classifyQuery()
  },
  mounted () {
    this.$refs.itemPanel1.style.left =
      this.$refs.navSide.offsetLeft + this.$refs.navSide.offsetWidth + 'px';
    this.$refs.itemPanel1.style.top = this.$refs.navSide.offsetTop + 'px';
   
  },
  updated () {
    this.$refs.itemPanel1.style.left =
      this.$refs.navSide.offsetLeft + this.$refs.navSide.offsetWidth + 'px';
    this.$refs.itemPanel1.style.top = this.$refs.navSide.offsetTop + 'px';
  },
  store
};
</script>

<style scoped>
.nav-item {
  width: 680px;
  height: 36px;
  margin: 0px auto;
}
.nav-item ul {
  list-style: none;
  margin-left: 15px;
}
.nav-item li {
  float: left;
  font-size: 16px;
  font-weight: bold;

  margin-left: 30px;
}
.nav-item a {
  text-decoration: none;
  color: #555555;
}
.nav-item a:hover {
  color: #d9534f;
}
/*大的导航信息，包含导航，幻灯片等*/
.nav-body {
  width: 1020px;
  height: 485px;
  margin: 0px auto;
}
.nav-side {
  width: 200px;
  height: 100%;
  padding: 0px;
  color: #fff;
  float: left;
  background-color: #6e6568;
}
.nav-side ul {
  width: 100%;
  padding: 0px;
  padding-top: 15px;
  list-style: none;
}
.nav-side li {
  padding: 7.5px;
  padding-left: 15px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;

}
.nav-side li:hover {
  background: #999395;
}
.nav-side-item:hover {
  cursor: pointer;
  color: #c81623;
}

/*导航内容*/
.nav-content {
  width: 792px;
  margin-left: 15px;
  overflow: hidden;
  float: left;
}
/*导航图片*/
.nav-show-img {
  margin-top: 10px;
  float: left;
}
.nav-show-img:nth-child(2) {
  margin-left: 12px;
}
/*显示商品*/
.content {
  width: 100%;
}
/*显示商品详细信息*/
.detail-item-panel {
  width: 815px;
  height: 485px;
  background-color: #fff;
  position: absolute;
  top: 168px;
  left: 389px;
  z-index: 999;
  overflow: auto;
}
.nav-detail-item {
  text-align: center;
  width: 100%;
  cursor: pointer;
  color: #eee;
  margin-top: 10px;
}
.nav-detail-item span {
  font-size: 12px;
  display: inline-block;
  width: 100px;
  height: 30px;
  line-height: 30px;
  background-color: #6e6568;
}
.nav-detail-item span:hover {
  margin-left: 15px;
  background-color: #f44336;
}
.detail-item-panel ul {
  list-style: none;
   display: flex;
  flex-wrap: wrap;
}
.detail-item-panel li {
  line-height: 38px;
    width: 30%;
    text-align: center;
    margin-top: 10px;
}
.detail-item-title {
  padding-right: 6px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: #555555;
}
.detail-item-title:hover {
  color: #d9534f;
}

.detail-item-row a {
  color: #555555;
}
.detail-item{
  font-size: 14px;
  padding-left: 12px;
  padding-right: 8px;
  cursor: pointer;
  border-left: 1px solid #ccc;
}
.detail-item:hover {
  color: #d9534f;
}
.classify_icon img,.detail-item-row img{
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 5px;
}
.classify_icon span{
  vertical-align: middle;
}
</style>
