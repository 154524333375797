import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import iView from 'iview';
import 'iview/dist/styles/iview.css';
import global from './global'//引用文件

Vue.use(iView);
Vue.config.productionTip = false;
Vue.prototype.global = global//挂载到Vue实例上面

router.beforeEach((to, from, next) => {
  iView.LoadingBar.start();
  next();
});

router.afterEach(route => {
  iView.LoadingBar.finish();
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
