import request from '@/utils/request'
import qs from 'qs'
import global from '@/global';
// 商品相关
// export function goodsQuery(cateIdOne,cateIdTwo,goodsName,choice,size,page) {
//  //添加品牌等级价格
//   return request({
//     url:'/web/query/qdd/goods/tabulation/template/bysomeparam',
//     method: 'post',
//     data: qs.stringify({
//       'brandId':256,//品牌id	
//       'cateIdOne':cateIdOne,//一级分类id			
//       'cateIdTwo':cateIdTwo,//二级分类id				
//       'goodsName':goodsName,//商品名称		
//       'choice':choice,//精选									
//       'size':size	,//defaultValue = “20”, required = false) 个数
//       'page':page,//defaultValue = “0”, required = false 当前页					
//     })
//   })
// }
//  export function goodsDetailZQuery(goodsId) {
//   //查询商品详情
//    return request({
//      url: '/web/query/qdd/goods/detail',
//      method: 'post',
//      data: qs.stringify({
//        'goodsId':goodsId,//商品id			
//       })
//    })
//  }
 
 export function goodsDescQuery(goodsId) {
  //查询商品描述
   return request({
     url: '/app/query/qdd/goods/description',
     method: 'post',
     data: qs.stringify({
       'goodsId':goodsId,//商品id			
      })
   })
 }

//  export function classifyQuery() {
//   // 查询分类模板
//   return request({
//     url: '/web/query/brand/goods/classification',
//     method: 'post',
//     data: qs.stringify({
//       'brandId':'256',//品牌id
//       'level': 1,
//       'isUpperShelf': 1,
//       'size':20,
//       'page':0,
//     })
//   })
// }


//  export function classifySonQuery(parentId) {
//   // 根据上级分类id查询商品分类模板
//   return request({
//     url:  '/web/query/brand/goods/classification/bybrandid/andparentid',
//     method: 'post',
//     data: qs.stringify({
//       'brandId': 256,//品牌id	
//       'parentId': parentId,//分类id	
//     })
//   })
// }


export function goodsQuery(cateIdOne,cateId,goodsName,choice,size,page,id,type,minPrice,maxPrice,makeId,isRecommend,isNeedUserId = '0',specialCateId) {
  //查询商品
   return request({
     url:'/app/query/goods/info',
     method: 'post',
     data: {
      'id':id,
      'brandId': global.brandId,
      'goodsName': goodsName, // 商品名称（支持模糊查询）
      'type': type, // 商品类型（0：普通商城专区，1：拼团专区，2：新人专享专区）
      'cateId': cateId, // 分类ID，（多个用逗号隔开）
      'page': page,
      'size': 10,
      'minPrice': minPrice, // 最低价
      'maxPrice': maxPrice, // 最高价
      'makeId': makeId, // 品牌ID
      'isRecommend': isRecommend, // 是否推荐，0：否，1：是
      'isNeedUserId': isNeedUserId, // 是否需要登录
      'specialCateId': specialCateId, // 特殊分类ID
      'size': size,		
     }
   })
 }

export function goodsDetailZQuery(goodsId) {
  //查询商品详情
  return request({
    url: '/app/query/goods/info',
    method: 'post',
    data: {
      'id':goodsId,//商品id			
      'isNeedUserId': '0',
      'brandId': global.brandId,
      'page': 0,
      'size': 10,
    }
  })
}

export function classifyQuery() {
  // 查询分类
  return request({
    url: '/app/query/goods/classification/info',
    method: 'post',
    data: {
      'brandId':global.brandId,//品牌id
      'level': 1,
      'isUpperShelf': 1,
      'size':20,
      'page':0,
    }
  })
}

export function classifySonQuery(parentId) {
  // 根据上级分类id查询商品分类模板
  return request({
    url:  '/app/query/goods/classification/info',
    method: 'post',
    data: {
      'brandId': global.brandId,//品牌id	
      'parentId': parentId,//分类id	
    }
  })
}

export function getServeToken(parameter){
  return request({
    url: '/app/login/client',
    method: 'post',
    data: Object.assign(parameter,{})
  })
}